<template>
    <v-main>
        <v-container>
            <v-row class="mb-2">
                <v-col class="text-h4">
                    Group Admin
                </v-col>
                <v-col
                    cols="5"
                    class="d-flex justify-end align-center"
                />
            </v-row>
            <template v-if="loading">
                <div v-for="i in 7" :key="i">
                    <v-skeleton-loader type="heading" class="mb-2" />
                    <v-skeleton-loader type="divider" class="mb-2" />
                    <v-col cols="4">
                        <v-skeleton-loader type="text,text,text,text" />
                    </v-col>
                </div>
            </template>
            <template v-else>
                <div
                    v-for="(grp, i) in groups"
                    :key="i"
                >
                    <v-row no-gutters>
                        <v-col class="text-h6" cols="6" v-text="grp.group.name" />
                        <v-col v-if="grp.sync" cols="6" class="text-right">
                            <v-icon
                                :class="{'rotating': syncGroups.includes(grp.group.name)}"
                                :disabled="syncGroups.includes(grp.group.name)"
                                @click.stop="!syncGroups.includes(grp.group.name) && syncGroup(grp.group.name, i)"
                            >mdi-sync</v-icon>
                        </v-col>
                        <v-col cols="12" class="text--secondary caption mt-n1" v-text="grp.description" />
                        <v-col cols="12" class="mt-2">
                            <ManageGroup
                                members-list-cols="12"
                                :ref="`mg${i}`"
                                :group="grp.group.name"
                                :members="grp.group.members"
                                :sync="grp.sync"
                                prevent-group-change
                            />
                        </v-col>
                    </v-row>
                    
                    <v-divider class="my-4" />
                </div>
            </template>
        </v-container>
    </v-main>
</template>
<script>
import { ref, onMounted } from '@vue/composition-api';
import axios from 'axios';
import ManageGroup from '@/components/admin/ManageGroup';

export default {
    components: {
        ManageGroup
    },
    setup(props, { root, refs }) {

        onMounted(() => {
            refresh();
        });

        const store = root.$store;

        const loading = ref(true);
        const groups = ref([]);
        const syncGroups = ref([]);

        const refresh = async () => {
            loading.value = true;
            const response = await axios.get('/groups/load_admin_groups/');

            groups.value = response.data;

            loading.value = false;
        };

        const syncGroup = async (g, i) => {
            syncGroups.value.push(g);

            let payload = {
                group: g
            };

            const response = await axios.post('/groups/sync_group/', payload);

            if (!response.data.error) {
                refs[`mg${i}`][0].setMembers(response.data.group.members);
                store.commit('setSuccessSnack', `${response.data.message}`);
            } else {
                store.commit('setErrorSnack', `Error: ${response.data.message}`);
            }

            syncGroups.value = syncGroups.value.filter(v => v !== g);
        }

        return {
            loading,
            groups,
            refresh,
            syncGroup,
            syncGroups
        };
    }
}
</script>