import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,[_c(VRow,{staticClass:"mb-2"},[_c(VCol,{staticClass:"text-h4"},[_vm._v(" Group Admin ")]),_c(VCol,{staticClass:"d-flex justify-end align-center",attrs:{"cols":"5"}})],1),(_vm.loading)?_vm._l((7),function(i){return _c('div',{key:i},[_c(VSkeletonLoader,{staticClass:"mb-2",attrs:{"type":"heading"}}),_c(VSkeletonLoader,{staticClass:"mb-2",attrs:{"type":"divider"}}),_c(VCol,{attrs:{"cols":"4"}},[_c(VSkeletonLoader,{attrs:{"type":"text,text,text,text"}})],1)],1)}):_vm._l((_vm.groups),function(grp,i){return _c('div',{key:i},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"text-h6",attrs:{"cols":"6"},domProps:{"textContent":_vm._s(grp.group.name)}}),(grp.sync)?_c(VCol,{staticClass:"text-right",attrs:{"cols":"6"}},[_c(VIcon,{class:{'rotating': _vm.syncGroups.includes(grp.group.name)},attrs:{"disabled":_vm.syncGroups.includes(grp.group.name)},on:{"click":function($event){$event.stopPropagation();!_vm.syncGroups.includes(grp.group.name) && _vm.syncGroup(grp.group.name, i)}}},[_vm._v("mdi-sync")])],1):_vm._e(),_c(VCol,{staticClass:"text--secondary caption mt-n1",attrs:{"cols":"12"},domProps:{"textContent":_vm._s(grp.description)}}),_c(VCol,{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('ManageGroup',{ref:("mg" + i),refInFor:true,attrs:{"members-list-cols":"12","group":grp.group.name,"members":grp.group.members,"sync":grp.sync,"prevent-group-change":""}})],1)],1),_c(VDivider,{staticClass:"my-4"})],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }